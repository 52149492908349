export const currencies = [
    'Abkhazian apsar',
    'Afghan afghani',
    'Albanian lek',
    'Alderney pound',
    'Algerian dinar',
    'Angolan kwanza',
    'Argentine peso',
    'Armenian dram',
    'Artsakh dram',
    'Aruban florin',
    'Ascension pound',
    'Australian dollar',
    'Azerbaijani manat',
    'Bahamian dollar',
    'Bahraini dinar',
    'Bangladeshi taka',
    'Barbadian dollar',
    'Belarusian ruble',
    'Belize dollar',
    'Bermudian dollar',
    'Bhutanese ngultrum',
    'Bolivian boliviano',
    'Bosnia and Herzegovina convertible mark',
    'Botswana pula',
    'Brazilian real',
    'British pound',
    'British Virgin Islands dollar',
    'Brunei dollar',
    'Bulgarian lev',
    'Burmese kyat',
    'Burundian franc',
    'Cambodian riel',
    'Canadian dollar',
    'Cape Verdean escudo',
    'Cayman Islands dollar',
    'Central African CFA franc',
    'CFP franc',
    'Chilean peso',
    'Chinese yuan',
    'Colombian peso',
    'Comorian franc',
    'Congolese franc',
    'Cook Islands dollar',
    'Costa Rican colón',
    'Croatian kuna',
    'Cuban convertible peso',
    'Cuban peso',
    'Czech koruna',
    'Danish krone',
    'Djiboutian franc',
    'Dominican peso',
    'Eastern Caribbean dollar',
    'Egyptian pound',
    'Eritrean nakfa',
    'Ethiopian birr',
    'Euro',
    'Falkland Islands pound',
    'Faroese króna',
    'Fijian dollar',
    'Gambian dalasi',
    'Georgian lari',
    'Ghanaian cedi',
    'Gibraltar pound',
    'Guatemalan quetzal',
    'Guernsey pound',
    'Guinean franc',
    'Guyanese dollar',
    'Haitian gourde',
    'Honduran lempira',
    'Hong Kong dollar',
    'Hungarian forint',
    'Icelandic króna',
    'Indian rupee',
    'Indonesian rupiah',
    'Iranian rial',
    'Iraqi dinar',
    'Israeli new shekel',
    'Jamaican dollar',
    'Japanese yen',
    'Jersey pound',
    'Jordanian dinar',
    'Kazakhstani tenge',
    'Kenyan shilling',
    'Kiribati dollar',
    'Kuwaiti dinar',
    'Kyrgyzstani som',
    'Lao kip',
    'Lebanese pound',
    'Lesotho loti',
    'Liberian dollar',
    'Libyan dinar',
    'Macanese pataca',
    'Macedonian denar',
    'Malagasy ariary',
    'Malawian kwacha',
    'Malaysian ringgit',
    'Maldivian rufiyaa',
    'Manx pound',
    'Mauritanian ouguiya',
    'Mauritian rupee',
    'Mexican peso',
    'Micronesian dollar',
    'Moldovan leu',
    'Mongolian tögrög',
    'Moroccan dirham',
    'Mozambican metical',
    'Namibian dollar',
    'Nauruan dollar',
    'Nepalese rupee',
    'Netherlands Antillean guilder',
    'New Taiwan dollar',
    'New Zealand dollar',
    'Nicaraguan córdoba',
    'Nigerian naira',
    'Niue dollar',
    'North Korean won',
    'Norwegian krone',
    'Omani rial',
    'Pakistani rupee',
    'Palauan dollar',
    'Panamanian balboa',
    'Papua New Guinean kina',
    'Paraguayan guaraní',
    'Peruvian sol',
    'Philippine piso',
    'Pitcairn Islands dollar',
    'Polish złoty',
    'Qatari riyal',
    'Romanian leu',
    'Russian ruble',
    'Rwandan franc',
    'Sahrawi peseta',
    'Saint Helena pound',
    'Samoan tālā',
    'Saudi riyal',
    'Serbian dinar',
    'Seychellois rupee',
    'Sierra Leonean leone',
    'Singapore dollar',
    'Solomon Islands dollar',
    'Somali shilling',
    'Somaliland shilling',
    'South African rand',
    'South Georgia and the South Sandwich Islands pound',
    'South Korean won',
    'South Sudanese pound',
    'Sri Lankan rupee',
    'Sudanese pound',
    'Surinamese dollar',
    'Swazi lilangeni',
    'Swedish krona',
    'Swiss franc',
    'Syrian pound',
    'São Tomé and Príncipe dobra',
    'Tajikistani somoni',
    'Tanzanian shilling',
    'Thai baht',
    'Tongan paʻanga',
    'Transnistrian ruble',
    'Trinidad and Tobago dollar',
    'Tristan da Cunha pound',
    'Tunisian dinar',
    'Turkish lira',
    'Turkmenistan manat',
    'Tuvaluan dollar',
    'Ugandan shilling',
    'Ukrainian hryvnia',
    'United Arab Emirates dirham',
    'United States dollar',
    'Uruguayan peso',
    'Uzbekistani soʻm',
    'Vanuatu vatu',
    'Venezuelan bolívar',
    'Vietnamese đồng',
    'West African CFA franc',
    'Yemeni rial',
    'Zambian kwacha',
]