import { useState } from "react";
import { Field, Form, Formik } from "formik";
import { NavLink, useHistory } from "react-router-dom";
import * as Yup from "yup";
import axios from 'axios'
import { api } from '../api'
import { SpinnerIcon } from "../components/SpinnerIcon"
import { currencies } from "../utils/currencies"
import toast from "react-hot-toast";


export function Contribute() {
    const [error, setError] = useState(null);

    let history = useHistory();

    const technologies = [
        "JavaScript",
        "React",
        "Vue",
        "Mobile App dev",
        "Devops",
        "Docker",
        "Kubernetes",
        "Cloud Services",
        "Celery / Async tech",
        "Django Rest Framework",
    ]

    function handleError(data: any) {
        if (data.detail) {
            setError(data.detail);
        } else {
            setError(data.message);
        }
    }

    return (
        <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
            <div className="max-w-xl mx-auto">
                <div className="mb-5 border-b pb-3">
                    <NavLink to="/" className="text-sm text-blue-500 hover:text-blue-600">← Go Back</NavLink>
                </div>
                <div className="text-center">
                    <h1 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                        Contribute to salary equality
                    </h1>
                    <p className="text-gray-700 mt-2">All of your information is anonymous, encrypted and
                        stored securely.</p>
                    {/* Or <NavLink to="/contribute/pdf/" className="text-blue-500 hover:text-blue-600">upload your salary slip /
                        offer letter</NavLink> */}
                </div>
                <div className="px-3 py-6 mt-5 bg-blue-50 text-blue-700 rounded-sm">
                    <p>Thank you for wanting to submit your salary information. Please note that
                        at the moment we are <span className="font-bold underline">only accepting full-time salaries</span>.
                    </p>
                    <p className="mt-5">
                        This means that if you are a contractor, freelancer or paid in any form other than a yearly salary,
                        we can't take your data right now. We are working on a similar submission for this kind of
                        data :)
                    </p>
                </div>
                {error && (
                    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-3">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <svg className="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    {error}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="mt-6">
                    <Formik
                        initialValues={{
                            title: "",
                            years_of_experience: "",
                            years_at_company: "",
                            currency: "United States dollar",
                            total_compensation: "",
                            salary: "",
                            location: "",
                            remote: false,
                            other_technologies: [],
                            other_details: "",
                        }}
                        validationSchema={Yup.object().shape({
                            title: Yup.string().required("Required"),
                            years_of_experience: Yup.number().min(0).max(50).required("Required"),
                            years_at_company: Yup.number().min(0).max(50).required("Required"),
                            currency: Yup.string().required("Please select a currency").oneOf(currencies),
                            total_compensation: Yup.number().min(1).required("Required"),
                            salary: Yup.number().min(1).required("Required"),
                            location: Yup.string().required("Required"),
                            remote: Yup.bool().required("Required"),
                        })}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            axios
                                .post(api.salaryList, values)
                                .then(() => {
                                    setSubmitting(false);
                                    resetForm();
                                    toast.success("Submission Successful!", {
                                        duration: 8000,
                                        icon: "🎉",
                                        iconTheme: {
                                            primary: "#000",
                                            secondary: "#fff"
                                        },
                                    });
                                    history.push('/')
                                })
                                .catch((err) => {
                                    setSubmitting(false);
                                    handleError(err.response.data)
                                });
                        }}
                    >
                        {({ isSubmitting, errors, touched }) => (
                            <Form className="space-y-8 divide-y divide-gray-200">
                                <div className="space-y-8 divide-y divide-gray-200">
                                    <div>
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Current Job and Work Experience</h3>
                                            <p className="mt-1 text-sm text-gray-500">
                                                This information will be displayed publicly.
                                            </p>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                            <div className="sm:col-span-6">
                                                <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                                    What is your job title?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.title && errors.title
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                                {touched.title && errors.title && (
                                                    <p className="mt-2 text-sm text-red-600">{errors.title}</p>
                                                )}
                                            </div>

                                            <div className="sm:col-span-6">
                                                <label htmlFor="years_of_experience" className="block text-sm font-medium text-gray-700">
                                                    How many years of experience do you have?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="number"
                                                        name="years_of_experience"
                                                        id="years_of_experience"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.years_of_experience && errors.years_of_experience
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                                {touched.years_of_experience && errors.years_of_experience && (
                                                    <p className="mt-2 text-sm text-red-600">{errors.years_of_experience}</p>
                                                )}
                                            </div>

                                            <div className="sm:col-span-6">
                                                <label htmlFor="years_at_company" className="block text-sm font-medium text-gray-700">
                                                    How many years have you worked for your company?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="number"
                                                        name="years_at_company"
                                                        id="years_at_company"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.years_at_company && errors.years_at_company
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                                {touched.years_at_company && errors.years_at_company && (
                                                    <p className="mt-2 text-sm text-red-600">{errors.years_at_company}</p>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div className="pt-8">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Salary Information</h3>
                                            <p className="mt-1 text-sm text-gray-500">
                                                This information will be displayed publicly.
                                            </p>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                            <div className="sm:col-span-6">
                                                <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                                                    What currency do you earn in?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        as="select"
                                                        id="currency"
                                                        name="currency"
                                                        default='United States dollar'
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                    >
                                                        {currencies.map(currency => {
                                                            return (
                                                                <option key={currency} value={currency}>{currency}</option>
                                                            )
                                                        })}
                                                    </Field>
                                                </div>
                                            </div>

                                            <div className="sm:col-span-6">
                                                <label htmlFor="total_compensation" className="block text-sm font-medium text-gray-700">
                                                    What is your total compensation (yearly)?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="number"
                                                        name="total_compensation"
                                                        id="total_compensation"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.total_compensation && errors.total_compensation
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                                <p className="mt-2 text-sm text-gray-500">Salary + Stock + Bonus. Look at your Tax filing.</p>
                                                {touched.total_compensation && errors.total_compensation && (
                                                    <p className="mt-2 text-sm text-red-600">{errors.total_compensation}</p>
                                                )}
                                            </div>

                                            <div className="sm:col-span-6">
                                                <label htmlFor="salary" className="block text-sm font-medium text-gray-700">
                                                    What is your salary (yearly)?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="number"
                                                        name="salary"
                                                        id="salary"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.salary && errors.salary
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                                <p className="mt-2 text-sm text-gray-500">At the moment we only accept yearly salary data.</p>
                                                {touched.salary && errors.salary && (
                                                    <p className="mt-2 text-sm text-red-600">{errors.salary}</p>
                                                )}
                                            </div>

                                        </div>
                                    </div>

                                    <div className="pt-8">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Geographical Information</h3>
                                            <p className="mt-1 text-sm text-gray-500">
                                                This information will be displayed publicly.
                                            </p>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                            <div className="sm:col-span-6">
                                                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                                                    Where do you live?
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        type="text"
                                                        name="location"
                                                        id="location"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.location && errors.location
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                                <p className="mt-2 text-sm text-gray-500">Format: City, State, Country</p>
                                                {touched.location && errors.location && (
                                                    <p className="mt-2 text-sm text-red-600">{errors.location}</p>
                                                )}
                                            </div>

                                            <div className="sm:col-span-6">
                                                <div>
                                                    <fieldset>
                                                        <div className="mt-4 space-y-4">
                                                            <div className="relative flex items-start">
                                                                <div className="flex items-center h-5">
                                                                    <Field
                                                                        type="checkbox"
                                                                        name="remote"
                                                                        id="remote"
                                                                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                                    />
                                                                </div>
                                                                <div className="ml-3 text-sm">
                                                                    <label htmlFor="remote" className="font-medium text-gray-700">
                                                                        I work remotely
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="pt-8">
                                        <div>
                                            <h3 className="text-lg leading-6 font-medium text-gray-900">Other Information</h3>
                                            <p className="mt-1 text-sm text-gray-500">
                                                This information will be displayed publicly.
                                            </p>
                                        </div>

                                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                                            <div className="sm:col-span-6">
                                                <div className="">
                                                    <fieldset>
                                                        <legend className="text-base font-medium text-gray-900">What other technologies do you work with?</legend>
                                                        <div className="mt-4 space-y-4">

                                                            {technologies.map(technology => {
                                                                return (
                                                                    <div className="relative flex items-start" key={technology}>
                                                                        <div className="flex items-center h-5">
                                                                            <Field type="checkbox" name="other_technologies" value={technology} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                                                                        </div>
                                                                        <div className="ml-3 text-sm">
                                                                            <label htmlFor="other_technologies" className="font-medium text-gray-700">
                                                                                {technology}
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })}

                                                        </div>
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div className="sm:col-span-6 mt-6">
                                                <label htmlFor="other_details" className="block text-sm font-medium text-gray-700">
                                                    Any other details you'd like to add (this will be visible)
                                                </label>
                                                <div className="mt-1">
                                                    <Field
                                                        as="textarea"
                                                        type="text"
                                                        name="other_details"
                                                        id="other_details"
                                                        className={`focus:outline-none shadow-sm block w-full sm:text-sm rounded-md ${touched.other_details && errors.other_details
                                                            ? "border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red"
                                                            : "border-gray-300"
                                                            }`}
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="pt-5">
                                    <div className="flex justify-end">
                                        <button
                                            disabled={isSubmitting}
                                            type="submit"
                                            className={`${isSubmitting ? "bg-indigo-500" : "bg-indigo-600 hover:bg-indigo-500"
                                                } inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 font-medium text-white shadow-sm focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5`}
                                        >
                                            {isSubmitting && <SpinnerIcon className="animate-spin ml-3 mr-3 h-5 w-5" />}
                                            {isSubmitting ? "Submitting" : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>


                </div>
            </div>
        </div>
    )
}