import { ENV } from "./config"

const debug = ENV === "development"
let base = "https://api.justdjango.com"
if (debug) {
    base = "http://127.0.0.1:8000"
}

export const api = {
    salaryList: `${base}/api/salaries/`,
    salaryProgress: `${base}/api/salaries/progress/`
}