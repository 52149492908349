import useSWR from "swr"
import axios from 'axios'
import { NavLink } from "react-router-dom"
import { api } from '../api'
import Logo from '../assets/images/logo.svg'

interface Salary {
    id: number
    title: string
    verified: boolean
    remote: boolean
    location: string
    salary: number
    currency: string
    years_of_experience: number
    years_at_company: number
    other_details: string
    other_technologies: string[]
}

function SalaryItem({ salary }: { salary: Salary }) {
    return (
        <li>
            <div className="block hover:bg-gray-50">
                <div className="px-4 py-4 sm:px-6">
                    <div className="flex items-center justify-between">
                        <div className="flex flex-row items-center">
                            <p className="text-sm font-medium text-indigo-600 truncate">
                                {salary.title}
                            </p>
                            {salary.verified && (
                                <div className="text-xs text-gray-500">
                                    <span className="ml-2">✅</span>
                                    <span className="ml-2">Verified</span>
                                </div>
                            )}
                        </div>
                        {salary.remote && (
                            <div className="ml-2 flex-shrink-0 flex">
                                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Remote
                                </p>
                            </div>
                        )}
                    </div>
                    <div className="mt-2 flex justify-between">
                        <div>
                            <p className="flex items-center text-sm text-gray-500">
                                📍
                                <span className="ml-2">{salary.location}</span>
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                💰
                                <span className="ml-2">{salary.salary} ({salary.currency})</span>
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                                ⏱
                                <span className="ml-2">
                                    {salary.years_at_company}{" "}Year{salary.years_at_company !== 1 && "s"} at the company
                                </span>
                            </p>
                        </div>
                        <div className="ml-2 md:hidden">
                            {salary.other_technologies.length > 0 ? (
                                <>
                                    <span className="mt-3 ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                        {salary.other_technologies[0]}
                                    </span>
                                    {salary.other_technologies.length > 1 && (
                                        <span className="mt-3 ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                            + {salary.other_technologies.length - 1} other{salary.other_technologies.length - 1 !== 1 && "s"}
                                        </span>
                                    )}
                                </>
                            ) : (
                                salary.other_technologies.map(tech => {
                                    return (
                                        <span key={tech} className="mt-3 ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                            {tech}
                                        </span>
                                    )
                                })
                            )}
                        </div>
                        <div className="ml-2 hidden md:block">
                            {salary.other_technologies.map(tech => {
                                return (
                                    <span key={tech} className="mt-3 ml-2 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                        {tech}
                                    </span>
                                )
                            })}
                        </div>
                    </div>

                </div>
            </div>
        </li>
    )
}

interface SalaryProgress {
    submissions: number
    progress: number
    goal: number
}

export function Home() {

    const { data: salaries } = useSWR<Salary[] | null>(
        api.salaryList,
        url => axios(url).then((res) => res.data), { revalidateOnFocus: false, initialData: null },
    );

    const { data: salaryProgress } = useSWR<SalaryProgress | null>(
        api.salaryProgress,
        url => axios(url).then((res) => res.data), { revalidateOnFocus: false, initialData: null },
    );

    return (

        <div className="min-h-screen bg-white">
            <div className="py-3">
                <main>
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">

                        <div className="relative overflow-hidden">
                            <div className="relative">
                                <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:px-6 md:mt-6 lg:mt-8">
                                    <div className="text-center">
                                        <a href="https://justdjango.com">
                                            <img src={Logo}
                                                className="h-12 w-12 sm:h-16 sm:w-16 mx-auto mb-6 sm:mb-10" alt="JustDjango" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="overflow-hidden">
                            <div className="pt-6 pb-16 sm:pb-24">
                                <main className="">
                                    <div className="mx-auto max-w-7xl">
                                        <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                                            <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                                                <div>

                                                    <h1 className="mt-4 text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                                                        <span className="block xl:inline">Salary data for</span>{' '}
                                                        <span className="block text-red-500 xl:inline">Django developers</span>
                                                    </h1>
                                                    <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                                        Salary equality is more important than ever. We're helping Django developers find
                                                        out what they're really worth.
                                                    </p>
                                                    {salaryProgress && (
                                                        <div className="mt-5">
                                                            <div className="mt-2 w-full">
                                                                Progress of Goal: <span
                                                                    className="text-indigo-600 font-semibold">{salaryProgress.progress}% - {salaryProgress.submissions} Salaries submitted
                                                                </span>
                                                            </div>
                                                            <div className="shadow w-full bg-gray-100 rounded-md">
                                                                <div className="bg-indigo-600 text-xs leading-none py-1 text-center text-white h-4"
                                                                    style={{ width: `${salaryProgress.progress}%` }}></div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="mt-5 sm:mt-8">
                                                        <a href="https://youtu.be/esyuo_0fZLY" target="_blank" rel="noreferrer noopener"
                                                            className="inline-flex items-center px-5 py-2 border border-transparent text-base font-medium rounded-md text-indigo-600 border-indigo-700 hover:text-white hover:bg-indigo-700 md:py-4 md:text-lg transition ease-in-out duration-200">
                                                            Watch the intro
                                                            <svg className="ml-3 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20" fill="currentColor">
                                                                <path fillRule="evenodd"
                                                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                                                                    clipRule="evenodd"></path>
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                                                <div className="bg-white shadow-md sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                                                    <div className="px-4 py-8 sm:px-10">
                                                        <h2 className="text-2xl text-gray-700 text-center">Contribute to salary equality</h2>
                                                        <ul className="mt-5 border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
                                                            <li className="py-4 flex md:border-t-0 md:py-0 md:pb-4">
                                                                <svg className="flex-shrink-0 h-6 w-6 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none" viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                        d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path>
                                                                </svg>
                                                                <span className="ml-3 text-base text-gray-500">Anonymous</span>
                                                            </li>

                                                            <li className="py-4 flex">
                                                                <svg className="flex-shrink-0 h-6 w-6 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                                                                </svg>
                                                                <span className="ml-3 text-base text-gray-500">30 seconds to complete</span>
                                                            </li>

                                                            <li className="py-4 flex">
                                                                <svg className="flex-shrink-0 h-6 w-6 text-gray-500"
                                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                                    stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                                                        d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"></path>
                                                                </svg>
                                                                <span className="ml-3 text-base text-gray-500">Verified</span>
                                                            </li>
                                                        </ul>

                                                        <p className="pt-5 border-t border-gray-200 text-gray-500">
                                                            Upload any of the following documents:
                                                        </p>

                                                        <span className="mt-3 inline-flex items-center px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                                            Offer letter
                                                        </span>
                                                        <span className="mt-3 inline-flex items-center ml-2 px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                                            Salary slip
                                                        </span>
                                                        <span className="mt-3 inline-flex items-center ml-2 px-2.5 py-0.5 rounded-md text-sm font-medium bg-gray-200 text-gray-700">
                                                            etc
                                                        </span>
                                                        {/* 
                                                        <div className="mt-6">
                                                            <a href="{% url 'add-salary' %}"
                                                                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                                Upload PDF
                                                            </a>
                                                        </div>

                                                        <div className="mt-5 relative">
                                                            <div className="absolute inset-0 flex items-center">
                                                                <div className="w-full border-t border-gray-300"></div>
                                                            </div>
                                                            <div className="relative flex justify-center text-sm">
                                                                <span className="px-2 bg-white text-gray-500">
                                                                    Or
                                                                </span>
                                                            </div>
                                                        </div> */}

                                                        <div className="mt-6">
                                                            <NavLink to="/contribute"
                                                                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                                Contribute Your Salary
                                                            </NavLink>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </main>

                                <div className="pt-16 sm:pt-12 max-w-7xl">
                                    <div className="relative">
                                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                            <div className="w-full border-t border-gray-300"></div>
                                        </div>
                                        <div className="relative flex justify-center">
                                            <span className="px-3 bg-white text-lg font-medium text-gray-900">
                                                Recently Added Salaries
                                            </span>
                                        </div>
                                    </div>

                                    <div className="bg-white shadow overflow-hidden sm:rounded-md">
                                        <ul className="divide-y divide-gray-200">
                                            {!salaries && Array(5)
                                                .fill(undefined)
                                                .map((i, j) => {
                                                    return (
                                                        <div className="animate-pulse flex space-x-4" key={j}>
                                                            <div className="flex-1 space-y-4 py-1">
                                                                <div className="rounded overflow-hidden">
                                                                    <div className="space-y-2 mt-5">
                                                                        <div className="h-4 bg-gray-300 rounded-sm w-1/2" />
                                                                        <div className="h-3 bg-gray-300 rounded-sm" />
                                                                        <div className="h-3 bg-gray-300 rounded-sm" />
                                                                        <div className="h-3 bg-gray-300 rounded-sm" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            {salaries && salaries.length === 0 && (
                                                <span className="mt-3">No results</span>
                                            )}
                                            {salaries && salaries.map((salary, i) => {
                                                return <SalaryItem salary={salary} key={salary.id} />
                                            })}
                                        </ul>
                                    </div>

                                </div>


                            </div>
                        </div>


                    </div>
                </main>
            </div>

            <footer className="bg-white">
                <div className="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden space-y-8 sm:px-6 lg:px-8">
                    <p className="mt-8 text-center text-base leading-6 text-gray-400">
                        Made by <a className="text-blue-500 hover:text-blue-600" href="https://twitter.com/justdjangocode"
                            rel="noreferrer noopener" target="_blank">@justdjangocode</a>.
                    </p>
                </div>
            </footer>
        </div>
    )
}
