import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Home } from './routes/Home'
import { Contribute } from './routes/Contribute'
import { Toaster } from "react-hot-toast";

export default function App() {
  return (
    <Router>
      <div>
        <Toaster position="top-right" reverseOrder={true} />
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/contribute">
            <Contribute />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

